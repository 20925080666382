#slideshow {
    margin-bottom: 30px;

    .slide {
        position: relative;
        background-position: center center;
        background-size: cover;
        width: 100%;
        min-height: 200px;

        img {
            display: none;
            width: 100%;
        }
    }

    ul.slick-dots {
        bottom: 20px;

        li:not(.slick-active) {
            button {
                background: #FFF;
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    #slideshow {
        .slide {
            background: none !important;

            img {
                display: block;
                width: 100%;
            }

            .content {
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 10;
                padding: 10px 30px;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    #slideshow {
        .slide {
            .content {
                h1 {
                    font-size: 40px;
                }

                h2 {
                    font-size: 24px;
                }
            }
        }
    }
}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {
    #slideshow {
        margin-bottom: 45px;

        .slide {
            .content {
                h1 {
                    font-size: 48px;
                }

                h2 {
                    font-size: 30px;
                }
            }
        }
    }
}