#news {
    .article {
        display: inline-block;

        img {
            width: 100%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }
        .date {
            font-size: 16px;
            color: #5F5F5F;
            margin: 15px 0 0;
            display: block;
        }
        h3 {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            margin: 0;
            margin-top: 10px;
            line-height: 1.1;
            margin-bottom: 30px;
            color: $black;
            transition: all 300ms ease;
        }

        &:hover {
            h3 {
                color: $color1;
            }
        }

        &.col-md-6 {
            h3 {
                font-size: 28px;
            }
        }
    }
}