#cart {
    .cart-row-no-mobile {
        display: none;
    }

    .product-options {
        list-style: none;
        padding-left: 0;
        margin-top: 10px;
    }
    
    .cart-row {
        width: 100%;
        display: flex;
        margin-bottom: 25px;

        .left {
            img {
                width: 100px;
                height: 100px;
            }
        }

        .right {
            margin-left: 15px;

            h5 {
                color: #000;
                font-size: 15px;
                font-weight: 600;
                margin: 0;
            }

            ul {
                list-style: none;
                padding-left: 0;
                margin-top: 10px;

                li {
                    font-size: 13px;
                    color: gray;

                    &.price {
                        font-size: 14px;
                        font-weight: bold;
                        color: #000000;
                    }
                }
            }

            p {
                margin-bottom: 0;
            }

            .amount-container {
                width: 100%;
                display: flex;

                .amount {
                    width: 55%;

                    form {
                        .form-group {
                            margin-bottom: 0;
                        }
                    }
                }

                .removal {
                    width: 45%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

#cart,
#order {
    h1 {
        .count {
            color: #a4b3bb;
        }
    }

    h4 {
        font-weight: 700;
        margin-top: 0;
    }

    table {
        width: 100%;

        thead {
            display: none;

            th {
                text-transform: uppercase;
                padding: 5px 0;
            }
        }

        tbody {
            tr {
                td {
                    display: block;
                    padding: 10px 0;

                    &.product {
                        a {
                            color: $black;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        dl {
                            margin-bottom: 0;
                            font-size: 14px;
                            dt {
                                text-transform: uppercase;
                            }
                        }
                    }

                    &.quantity {
                        form {
                            .form-group {
                                margin-bottom: 0;
                                .input-group {
                                    width: 130px;
                                }
                            }
                        }
                    }

                    &.price {
                        font-size: 14px;
                    }

                    &.subtotal {
                        font-size: 14px;
                    }

                    &.actions {
                        text-align: left;

                        .btn {
                            background: $color1;
                            color: $white;
                            font-size: 24px;

                            &:hover {
                                background: shade($color1, 15);
                                color: $white;
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: 1px solid $grey;
                    }
                }
            }
        }
    }

    dl {
        dt {
            float: left;
            clear: left;
            text-align: right;
            white-space: nowrap;
            margin-right: 15px;
        }
    }

    .loader {
        padding: 50px 0;
    }

    .empty-cart {
        border: 1px solid #bce8f1;
        background-color: #d9edf7;
        padding: 30px 0;

        h2 {
            font-size: 18px;
            margin: 0;
            color: #31708f;

            a {
                text-decoration: underline;
            }
        }
    }

    .summary {
        padding: 20px 0;
        font-size: 14px;
    }

    .discount-coupon {
        .code {
            font-size: 18px;

            b {
                font-family: Consolas, "Liberation Mono", Courier, monospace;
            }
        }

        .remove {
            font-size: 28px;
            font-weight: 700;
            line-height: 26px;
            color: #000;
        }
    }

    .separator {
        border-bottom: 1px solid $grey;
    }

    .checkout {
        padding: 20px 0;
        font-size: 14px;

        .total {
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    input[name="quantity"] {
        text-align: center;
    }
}

@media (min-width: $screen-sm-min) {
    #cart,
    #order {
        .cart-row-no-mobile {
            display: table-row;
        }

        .cart-row-mobile {
            display: none;
        }

        table {
            width: 100%;

            thead {
                display: table-header-group;

                th {
                    &.quantity {
                        width: 150px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        display: table-cell;

                        &.actions {
                            text-align: right;
                        }
                    }
                }
            }

            th, td {
                border-bottom: 1px solid $grey;
            }
        }
    }
}