@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 1.8;
    overflow-y: scroll;
}

.container {
    max-width: 1440px;
    width: initial;
    position: relative;
}

a {
    color: $color2;
    cursor: pointer;

    &:hover,
    &:focus {
        color: #000;
        text-decoration: none;
    }
}

h1, h2 {
    font-family: 'Open Sans Condensed', serif;
    font-size: 32px;
    margin: 0 0 20px 0;
    font-weight: 700;
    line-height: 1.3;
    text-transform: uppercase;

    .grey {
        color: $grey;
    }
}

h2 {
    position: relative;
    font-size: 24px;
    color: $color1;
    margin: 0 0 30px 0;

    &.line {
        text-align: center;

        &:before {
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
            content: ' ';
            width: 100%;
            height: 1px;
            background: #E5E5E5;
        }

        span {
            background: #FFF;
            padding: 0 15px;
        }
    }
}

p {
    margin-bottom: 30px;
}

.breadcrumb {
    padding: 8px 0;
    margin-top: 20px;
    margin-bottom: 0;
    list-style: none;

    li {
        display: inline-block;
        color: #a4b3bb;

        a {
            color: #a4b3bb;
        }

        + li:before {
            padding: 0 5px;
            color: #a4b3bb;
            content: "/\00a0";
        }
    }
}

.modal-content {
    border-radius: 0px;
}

.w100 {
    width: 100%;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

@media (min-width: $screen-sm-min) {
    .container {
        max-width: 1440px;
        width: initial;
    }

    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: $screen-md-min) {
    h1 {
        font-size: 30px;
    }

    .container {
        max-width: 1440px;
        width: initial;
    }

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: $screen-lg-min) {
    .container {
        max-width: 1440px;
        width: initial;
    }

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}