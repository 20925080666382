#article {
    margin: 50px 0;

    article {
        .date {
            display: block;
            color: #5F5F5F;
            margin: 30px 0;
        }

        h1 {
            margin: 0;
        }

        .intro {
            font-size: 20px;
            margin: 15px 0;
        }
    }

    .more-articles {
        h2 {
            margin: 0 0 15px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
        }
        a {
            display: block;
            border-top: 1px solid #EFF1F3;
            
            &:last-child {
                border-bottom: 1px solid #EFF1F3;
            }

            > article {
                padding: 15px 0;
                color: $black;
                transition: all 300ms ease;
    
                img {
                    border: 5px solid $white;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }

                &:hover,
                &.active {
                    color: $color1;
                }
            }
        }
    }

    .share {
        border-bottom: 1px solid #D6DDE0;
        
        ul {
            margin: 0;
            padding: 15px 0;

            li {
                display: inline-block;
                padding: 0 5px;
                list-style: none;

                &:first-child {
                    padding-left: 0;
                }

                a {
                    color: $black;
                    text-decoration: none;
                    transition: all 300ms ease;
                    font-size: 18px;

                    &:hover {
                        color: $color1;
                    }
                }
            }
        }
    }
}