footer {
    background: #f0f3f5;
    color: #000;
    padding: 30px 0 15px 0;
    text-align: center;
    margin-top: 30px;

    h3 {
        font-family: 'Open Sans Condensed', serif;
        margin: 0 0 30px 0;
        font-size: 28px;
        font-weight: 700;
    }

    h4 {
        font-weight: bold;
        font-size: 16px;
        margin-top: 0;
    }

    nav {
        overflow: hidden;

        > ul {
            margin: 0 0 30px 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0 0 30px 0;

            li {
                a {
                    color: #000;

                    &:hover {
                        color: $color1;
                    }
                }
            }
        }

    }

    .social {
        margin-bottom: 30px;

        .icon {
            display: inline-block;
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background: #FFF;
            color: $color1;
            line-height: 60px;
            text-align: center;
            font-size: 28px;
            margin-right: 5px;
            transition: all 0.3s;

            &:last-of-type {
                margin-right: 0;
            }

            &:hover {
                background: $color1;
                color: #FFF;
            }
        }
    }

    #frmNewsletter {
        .form-control {
            height: auto;
            padding: 10px;
            background-clip: padding-box;
            border: none;
            max-width: 360px;
            margin: 0 auto;
        }
    }

    .bottom {
        margin-top: 30px;
        border-top: 1px solid #acacac;
        padding-top: 15px;
        color: #CCC;
        font-size: 13px;
        line-height: 35px;

        a {
            color: #CCC;
            text-decoration: underline;
        }

        figure {
            margin-top: 30px;

            img {
                height: 35px;
                margin-right: 15px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    footer {
        nav {
            > ul {
                overflow: hidden;
                display: inline-block;

                > li {
                    float: left;
                    margin-right: 150px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                ul {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    footer {
        .bottom {
            figure {
                margin-top: 0;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    footer {
        text-align: left;
        
        .footer-social-media {
            text-align: right;
        }
        
        nav {
            > ul {
                margin-bottom: 0;
            }
        }
    }
}