#product {
  margin-top: 50px;

  .gallery {
    margin-bottom: 30px;

    .images {
      .slide {
        outline: none;

        a {
          outline: none;

          img {
            margin: 0 auto;
            max-width: 100%;
          }
        }
      }
    }
    .nav {
      .slide {
        padding: 15px;

        img {
          width: 100%;
          transition: all 300ms ease;

        }
        &.slick-current {
          img {
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }

  .price {
    color: $color1;
    font-size: 32px;
    font-weight: bold;

    .old {
      font-size: 22px;
      text-decoration: line-through;
      font-weight: normal;
      margin-right: 15px;
    }
  }

  .delivery-stock-information {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 15px;

    .stock_visible {
      i {
        margin-right: 10px;
      }
    }

    .delivery_time_visible {
      display: none;

      i {
        margin-right: 10px;
      }
    }

  }

  .row {
    position: relative;

    .value_option {
      margin: 15px 0;
    }

    .values {
      > span {
        position: relative;
        display: block;
        min-width: 14.28%;
        float: left;
        text-align: center;
        padding: 15px 0;
        border-right: 1px solid #D6DDE0;
        border-bottom: 1px solid #D6DDE0;
        background: $white;
        color: $black;
        text-decoration: none;
        transition: all 300ms ease;
        cursor: pointer;

        &:first-child,
        &:nth-child(8),
        &:nth-child(15) {
          border-left: 1px solid #D6DDE0;
        }
        &:nth-child(-n+7) {
          border-top: 1px solid #D6DDE0;
        }

        &.disabled {
          color: #D1D9DD;
          cursor: not-allowed;
        }

        .delivery_time {
          position: absolute;
          left: 50%;
          top: -10px;
          color: #fff;
          text-transform: uppercase;
          font-size: 12px;
          padding: 2px 5px;
          white-space: nowrap;
          display: none;
          z-index: 1;
          background: $color1;

          &.direct {
            background: #4cb050;
          }
        }

        //&:hover:not(.disabled) {
        //  background: #D6DDE0;
        //
        //  .delivery_time {
        //    display: block;
        //  }
        //}

        &.selected {
          background: #d9edf7;
        }
      }

      &::after {
        content: "";
        clear: both;
        display: table;
      }
    }

    .btn_buy {
      text-transform: none;
      text-align: center;
      padding: 5px 15px;
      font-size: 20px;
      height: auto;
      line-height: auto;
    }

    .address {
      h3 {
        color: $color1;
        text-transform: uppercase;
        font-size: 14px;
      }

      .verzending {
        margin-top: 50px;
        color: $color1;
        font-size: 14px;

        strong {
          display: block;
          font-size: 16px;
        }
      }
    }
  }

  .points {
    margin-top: 30px;

    .inner {
      padding: 15px 30px;
      background: #F0F3F5;

      ul {
        margin: 0;
        padding: 0;

        li {
          position: relative;
          list-style: none;
          padding: 7px 0 7px 25px;

          &:before {
            content: '\e903';
            position: absolute;
            font-family: 'icomoon';
            color: $color1;
            left: 0;
            top: 7px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .bottom {
    margin-top: 30px;

    .share {
      text-align: center;
    }
    .logos {
      margin-top: 15px;
      text-align: center;

      a {
        display: inline-block;
        height: 50px;
        width: 92px;
        padding: 0 5px;

        background-image: url('/img/logo-footer.jpg');
        background-size: 100% 350px;

        &:last-child {
          padding-right: 0;
        }

        &.keurmerk {
          background-position: 0 0;
        }
        &.postnl {
          background-position: 0 400px;
        }
      }
    }
  }

  .tabs {
    margin-top: 30px;

    nav {
      border-bottom: 1px solid #D6DDE0;

      ul {
        padding: 0;
        margin: 0;

        li {
          display: block;
          list-style: none;
          cursor: pointer;
          padding: 10px 0;
          font-size: 17px;
          text-transform: uppercase;
          transition: all 300ms ease;

          &:hover,
          &.active {
            color: $color1;
          }
        }
      }
    }

    .tab-contents {
      position: relative;

      .tab-content {
        padding: 30px 0;
        display: none;
        opacity: 0;
        visibility: hidden;
        font-size: 18px;
        transition: all 300ms ease;

        ul {
          padding-left: 20px;
          margin: 15px 0;
        }

        &.active {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

#productCartModal {
  .modal-body {
    position: static;
  }

  header {
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #CCC;
    padding-bottom: 15px;
    padding-right: 25px;

    span {
      color: $color1;
      margin-right: 10px;
    }
  }

  .close {
    position: absolute;
    font-size: 28px;
    top: 5px;
    right: 13px;
    cursor: pointer;
  }

  .image_wrapper {
    padding: 30px 0;

    img {
      width: 100%;
    }
  }

  .content_wrapper {
    padding: 30px 15px;

    h2 {
      font-size: 30px;
      font-weight: bold;
      margin: 0 0 15px 0;
    }

    .price {
      color: $color1;
      font-size: 26px;
      font-weight: bold;

      .old {
        font-size: 20px;
        text-decoration: line-through;
        font-weight: normal;
        margin-right: 15px;
      }
    }

    .options {
      list-style: none;
      margin: 15px 0 0 0;
      border-top: 1px solid #d6dde0;
      padding: 15px 0 0 0;
    }
  }

  .btn {
    text-transform: none;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-size: 18px;
  }
}

@media (min-width: $screen-sm-min) {
  #product {
    .tabs {
      nav {
        ul {
          li {
            display: inline-block;
            padding: 10px 15px;

            &:first-child {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  #product {
    .points {
      .inner {
        ul {
          column-count: 2;
          column-gap: 15px;
        }
      }
    }
    .bottom {
      .share {
        float: left;
        text-align: inherit;
      }
      .logos {
        margin-top: 0;
        float: right;
        text-align: inherit;
      }
    }

    .row {
      .values {
        > span {
          &:hover:not(.disabled) {
            background: #D6DDE0;

            .delivery_time {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  #product {
    .row {
      .address {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 33.33%;

        .col-sm-6 {
          width: 100% !important;
        }
      }
    }
  }
}