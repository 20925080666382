#page {
    h1 {
        margin: 0;
    }
    .intro {
        font-size: 20px;
        margin: 15px 0;
    }
    p {
        margin: 15px 0;
        line-height: 1.5;
    }
}