#home {

    #slideshow {
        margin-top: 30px;
    }

    .banner_wrapper {
        img {
            width: 100%;
        }

        p {
            margin: 10px 0 20px 0;
            text-align: center;

            a {
                color: #000;
            }
        }
    }

    .main-heading {
        margin-bottom: 30px;
        font-size: 20px;
    }

    .category_wrapper {
        h2 {
            font-size: 16px;
        }
    }
}

@media (min-width: $screen-sm-min) {

}

@media (min-width: $screen-lg-min) {
    #home {

        #slideshow {
            margin-top: 0;
        }

        .main-heading {
            margin-bottom: 30px;
            font-size: 32px;
        }

        .category_wrapper {
            h2 {
                font-size: 24px;
            }
        }
    }
}