#checkout {
  .steps {
    padding: 20px 0 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $grey;

    .step {
      display: inline-block;
      margin-right: 25px;
      text-transform: uppercase;
      font-weight: 700;
      color: #a4b3bb;

      .number {
        height: 25px;
        width: 25px;
        display: inline-block;
        border-radius: 50%;
        background-color: $grey;
        color: #a4b3bb;
        text-align: center;
        margin-right: 5px;
      }

      &.active {
        .number {
          background-color: $color1;
          color: $white;
        }
        color: $black;
      }
    }
  }

  form {
    label {
      font-weight: 400;
    }

    .form-group {
      &.has-error {
        input,
        select,
        textarea {
          //background-color: #f9f2f4;
          border-color: #a94442;
        }

        .help-block {
          font-size: 12px;
        }
      }
    }

    input[type="radio"] {
      margin-right: 15px;
    }

    .gender {
      label {
        margin-right: 25px;
      }
    }

    .shipping-address {
      display: none;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid $grey;
    padding: 10px 0;
  }

  .loader {
    padding: 50px 0;
  }

  .method {
    margin-bottom: 10px;

    input[type="checkbox"] {
      position: relative;
      margin: 0;
    }

    .image,
    .description {
      margin-left: 15px;
    }
  }
}

@media (min-width: $screen-sm-min) {
  #checkout {

  }
}