form {
    .form-group {
        .form-control {
            border-radius: 0;
            box-shadow: none;
            border: 1px solid $grey;

            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder {
                color: $grey;
            }
        }
    }

    &.form {
        div {
            > .form-group {
                label {
                    font-weight: normal;
                }

                input,
                textarea {
                    &.form-control {
                        border-bottom: 2px solid $grey;
                        resize: none;
                        font-size: 16px;
                        height: auto;
                        background-clip: padding-box;
                    }
                }

                &.has-error {
                    input {
                        border-color: #a94442;
                    }

                    .feedback {
                       color: #a94442;
                    }
                }
            }
        }
    }
}