#filter {
    margin-bottom: 30px;

    h2 {
        position: relative;
        cursor: pointer;

        .icon {
            transition: all 300ms ease;
            font-size: 20px;
            position: absolute;
            top: 3px;
            right: 0;
        }
    }

    .filter_wrapper {
        display: none;
        border-top: 1px solid #D5DDDF;

        .part {
            border-bottom: 1px solid #D5DDDF;
            overflow-y: hidden;
            overflow-x: visible;
            transition: all 300ms ease;

            h3 {
                margin: 0;
                padding: 20px 0;
                font-size: 16px;
                text-transform: uppercase;
                cursor: pointer;

                span {
                    position: relative;
                    float: right;
                    font-size: 16px;

                    .icon {
                        transition: all 300ms ease;

                        &.icon-plus {
                            position: absolute;
                            right: 0;
                            opacity: 1;
                        }

                        &.icon-minus {
                            position: absolute;
                            right: 0;
                            opacity: 0;
                        }
                    }

                }
            }

            .content {
                display: none;
                padding: 0 0 20px 0;
            }

            &.active {
                h3 {
                    span {
                        .icon-plus {
                            opacity: 0;
                        }

                        .icon-minus {
                            opacity: 1;
                        }
                    }
                }
            }

            &.subcategory {
                h3 {
                    padding-top: 20px;
                }

                hr {
                    margin: 10px 0;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        line-height: 2;

                        a {
                            color: #000;

                            &:hover,
                            &.active {
                                color: $color1;
                            }

                            i {
                                font-size: 12px;
                                margin-left: 2px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }

            &.price {
                .content {
                    padding-bottom: 40px;

                    .form-group {
                        display: inline-block;
                        margin: 0 0 10px 0;
                        float: right;

                        &:first-child {
                            float: left;
                        }

                        input {
                            width: 80px;
                            text-align: center;
                        }
                    }
                }
            }

            &.values {
                overflow: hidden;

                .value {
                    float: left;
                    display: block;
                    text-align: center;
                    padding: 15px;
                    border: 1px solid #D6DDE0;
                    background: $white;
                    color: $black;
                    text-decoration: none;
                    transition: all 300ms ease;
                    margin: 0 5px 5px 0;

                    &.disabled {
                        color: #D1D9DD;
                        cursor: normal;
                    }

                    &:hover {
                        &:not(.disabled) {
                            background: #D6DDE0;
                        }
                    }
                    &.active {
                        &:not(.disabled) {
                            background: $color1;
                            color: $white;
                        }
                    }
                }
            }

            &.brand {
                .form-group {
                    .checkbox {
                        label {
                            padding-left: 25px;

                            input[type="checkbox"] {
                                outline: none;
                                position: absolute;
                                left: 0;
                                top: 1px;
                                margin-left: 0;
                                margin-top: 0;
                                appearance: none;
                                width: 16px;
                                height: 16px;
                                border: 1px solid #D5DDDF;

                                &:after {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    content: '\e903';
                                    font-family: 'icomoon';
                                    color: $black;
                                    font-size: 11px;
                                    opacity: 0;
                                    transition: all 300ms ease;
                                }

                                &:checked {
                                    &:after {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }

                .more-brands {
                    display: none;
                }

                .more {
                    cursor: pointer;
                    color: $color1;
                    padding-left: 25px;

                    .icon {
                        font-size: 12px;
                    }
                }
            }

            &.category {
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        line-height: 2;

                        a {
                            color: #000;

                            &:hover,
                            &.active {
                                color: $color1;
                            }

                            i {
                                font-size: 12px;
                                margin-left: 2px;
                                margin-right: 8px;
                            }

                            &.active:hover {
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #filter {
        h2 {
            cursor: default;
        }
    }
}