#faq {
    h1 {
        margin: 0;
    }
    .intro {
        font-size: 20px;
        margin: 15px 0;
    }
    p {
        margin: 15px 0;
        line-height: 1.5;
    }

    ul.questions {
        list-style: none;
        margin: 30px 0 0 0;
        padding: 0;

        li.question {
            margin-bottom: 15px;
            outline: 0;

            .answer {
                display: none;
                padding: 8px 15px;

                p {
                    margin-bottom: 0;
                }
            }

            ul {
                list-style: disc;
                margin: 15px 0 0 30px;
                padding: 0;
            }

            .heading {
                display: block;
                background: #EEE;
                font-weight: bold;
                padding: 8px 15px;
                cursor: pointer;
                border-bottom: 1px solid #CCC;
            }

            &.show {
                .heading {
                    background: $color1;
                    color: #FFF;
                    border-bottom: 1px solid $color1;
                }

                .answer {
                    display: block;
                }
            }
        }
    }
}