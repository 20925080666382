#products {
    .page-content {
        margin-bottom: 30px;

        .text {
            float: left;
            margin: 15px 0;
            overflow: hidden;
            transition: all 300ms ease;
        }

        .title {
            .order_wrapper {
                label {
                    display: none;
                }
            }
        }

        .read-more {
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }

    .pagination_wrapper {
        border-top: 1px solid #D5DDDF;
    }
}

@media (min-width: $screen-sm-min) {
    #products {
        .filter {
            position: absolute;
            top: 0;
            left: -500px;
            width: 500px;
            border: 1px solid #D5DDDF;
            border-left: none;
            z-index: 5;
            background: $white;
            transition: all 300ms ease;
            padding-top: 20px;


            .open-filter {
                position: absolute;
                right: -50px;
                top: 20px;
                height: 50px;
                width: 50px;
                line-height: 50px;
                border: 1px solid #D5DDDF;
                border-left: none;
                text-align: center;
                background: $white;
                cursor: pointer;
            }

            &.active {
                left: 0;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }
        }
        .page-content {
            .text {
                float: none;
            }
            .title {
                overflow: hidden;

                h1 {
                    float: left;
                    margin-left: 50px;
                }

                .order_wrapper {
                    float: right;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #products {
        .filter {
            position: static;
            box-shadow: none;
            border: none;
            width: 16.6666666667%;
            padding-top: 0;
            top: inherit;
            left: inherit;
        }

        .page-content {
            .title {
                h1 {
                    margin-left: 0px;
                }
            }
        }
    }
}