.products {
    .product {
        padding-bottom: 30px;

        .top {
            position: relative;
            padding: 15px;
            background: $white;
            text-align: center;
            box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
            min-height: 210px;

            .label {
                position: absolute;
                display: inline-block;
                padding: 5px 10px;
                background: $color1;
                color: $white;
                text-transform: uppercase;
                font-weight: lighter;
                top: 0;
                left: 0;
                z-index: 2;
            }

            img {
                width: 100%;
            }

            .overlay {
                position: absolute;
                background: rgba(0, 0, 0, 0.75);
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                padding: 0 15px;
                opacity: 0;
                z-index: 1;
                transition: all 300ms ease;

                .btn_wrapper {
                    position: absolute;
                    width: calc(100% - 30px);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    .btn:first-of-type {
                        margin-bottom: 15px;
                    }

                    p {
                        color: #FFF;
                        margin: 6px 0 0 0;
                    }
                }
            }
        }

        .bottom {
            padding: 15px 0;
            position: relative;

            h2 {
                margin: 0;
                display: block;
                font-size: 18px;
                margin-bottom: 5px;
                padding-right: 30px;
            }

            .price {
                color: #000;
                font-weight: bold;
                font-size: 16px;

                .old {
                    text-decoration: line-through;
                    margin-right: 10px;
                    font-weight: normal;
                }
            }

            .favorite {
                position: absolute;
                right: 0;
                top: 17px;
                font-size: 18px;
                color: #CCC;
                cursor:pointer;

                &:hover {
                    color: #000;
                }

                &.active {
                    color: $color1;
                }
            }
        }
        &:hover {
            .top {
                .overlay {
                    opacity: 1;
                }
            }
        }
    }
}