header {
    position: relative;
    background: $white;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    

    .logo,
    .menu-btn,
    .search-btn,
    .cart-btn {
        display: inline-block;
    }

    .logo {
        height: 60px;
        margin: 5px 0 10px 0;
    }

    .menu-btn,
    .search-btn,
    .cart-btn,
    .user-btn,
    .fav-btn {
        position: absolute;
        right: 15px;
        top: 25px;
        color: $black;
        text-decoration: none;
        font-size: 16px;

        &.active {
            color: $color1;
        }
    }

    .cart-btn {
        font-size: 18px;
        top: 23px;
        right: 50px;

        .amount {
            span {
                position: absolute;
                background: $color1;
                color: $white;
                font-size: 10px;
                top: -6px;
                right: -6px;
                text-align: center;
                padding: 2px 4px;
                line-height: 1;
            }
        }
    }

    .search-btn {
        right: 110px;
        top: 26px;
    }

    .user-btn {
        right: 80px;
        font-size: 18px;
        top: 23px;
    }

    .fav-btn {
        right: 140px;
        top: 25px;
        font-size: 17px;
    }

    nav {
        position: relative;
        background: $color1;
        overflow: hidden;
        display: none;

        ul {
            padding: 0;
            margin: 0 -15px;

            li {
                display: block;
                list-style: none;
                transition: all 300ms ease;
                height: 46px;
                overflow: hidden;
                transition-delay: 600ms;

                a {
                    position: relative;
                    display: block;
                    padding: 10px 15px;
                    border-bottom: 1px solid #EEE;
                    color: $white;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    height: 46px;
                    transition: all 300ms ease;

                    span {
                        display: inline-block;
                        position: relative;
                        left: 0;
                        transition: left 300ms ease 300ms, transform 300ms ease 300ms;
                    }

                    &:before {
                        content: '\e90d';
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: 'icomoon';
                        color: $black;
                        opacity: 0;
                        transition: all 300ms ease;
                        transition-delay: 0ms;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &:hover {
                        color: #000;
                    }

                }

                &.open {
                    > a {
                        border-bottom: 1px solid $color1;
                        color: $white;
                        transition-delay: 600ms;
                        overflow: hidden;

                        span {
                            left: 50%;
                            transform: translateX(-50%);
                            transition-delay: 0ms;
                        }

                        &:before,
                        &:after {
                            opacity: 1;
                            transition-delay: 300ms;
                        }

                        &.hide {
                            padding: 0px 15px;
                            height: 0;
                            border: none;
                        }
                    }
                }

                > ul {
                    margin: 0;
                }
            }

            li:last-child a {
                border-bottom: none;
            }

            &.sub-open {
                > li {
                    transition-delay: 300ms;

                    &:not(.open) {
                        height: 0;
                    }
                }
            }
        }
    }

    .search,
    .user {
        background: $white;
        display: none;
        border-top: 1px solid #e5e5e5;
        margin: 0 -15px;
        padding: 0 15px;

        form {
            margin-top: 15px;
            position: relative;

            .form-group {
                .form-control {
                    height: auto;
                    padding: 10px;
                    background-clip: padding-box;
                    border-color: #e5e5e5;
                    background: #f9f9f9;
                }
            }
        }

        ul.register {
            list-style: none;
            margin: 0 0 15px 0;
            padding: 0;

            li {
                position: relative;
                display: inline-block;
                font-size: 13px;

                &:first-child {
                    padding-right: 12px;
                    margin-right: 8px;

                    &:after {
                        content: ' ';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 1px;
                        height: 20px;
                        background: #EEE;
                    }
                }
            }
        }

        ul.account {
            padding: 0;
            margin: 0 -15px;

            li {
                display: block;
                list-style: none;
                transition: all 300ms ease;
                height: 46px;
                overflow: hidden;
                transition-delay: 600ms;

                a {
                    position: relative;
                    text-align: right;
                    display: block;
                    padding: 10px 15px;
                    border-bottom: 1px solid #EEE;
                    color: $color2;
                    height: 46px;
                    transition: all 300ms ease;

                    span {
                        display: inline-block;
                        position: relative;
                        left: 0;
                        transition: left 300ms ease 300ms, transform 300ms ease 300ms;
                    }

                    &:before {
                        content: '\e90d';
                        position: absolute;
                        left: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: 'icomoon';
                        color: $black;
                        opacity: 0;
                        transition: all 300ms ease;
                        transition-delay: 0ms;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &:hover {
                        color: #000;
                    }

                }
            }

            li:last-child a {
                border-bottom: none;
            }
        }

    }

    .search {
        form {
            .form-group,
            button {
                float: left;
            }
            .form-group {
                width: calc(100% - 42px);

                .form-control {
                    border-right: none;
                }
            }

            button {
                padding: 10px;
                height: 42px;
                width: 42px;
                border-radius: 0;
                border: none;
                background: $color1;
                color: #FFF;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    header {
        .logo {
            height: 80px;
        }

        .menu-btn {
            top: 35px;
        }

        .cart-btn {
            top: 33px;
        }

        .search-btn {
            top: 36px;
        }

        .user-btn {
            top: 34px;
        }

        .fav-btn {
            top: 34px;
        }

        .cart {
            width: initial;
            left: initial;
            height: initial;
            right: 0;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

            .products {
                max-height: 425px;
            }

            .total-row {
                .btn {
                    &:first-child {
                        margin-right: 10px;
                    }
                    .icon {
                        margin-right: 10px;
                    }
                    span {
                        display: inline-block;
                    }
                }
            }
        }

        .user {
            .form-group:last-of-type {
                float: left;
            }

            ul {
                float: right;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    header {
        .top {
            position: relative;
            background: #f0f3f5;
            color: #333;
            z-index: 2;
            font-size: 13px;
            line-height: 20px;

            .icon {
                font-size: 12px;
                color: $color1;

                &.icon-cell_phone {
                    font-size: 18px;
                    vertical-align: -2px;
                }
            }

            span {
                padding: 10px 10px;
                display: inline-block;

                &.pull-right {
                    font-weight: bold;
                }
            }
        }

        .logo {
            height: 120px;
        }

        .search {
            position: absolute;
            left: 50%;
            top: 44px;
            display: block !important;
            width: 100%;
            max-width: 400px;
            transform: translate(-50%, 0);
            padding: 0;
            border-top: 0;

            form {
                margin-top: 0;

                .form-group {
                    margin-bottom: 0;
                    width: calc(100% - 50px);

                    .form-control {
                        line-height: 48px;
                        height: 50px;
                        padding: 0 15px;
                    }
                }

                button {
                    height: 50px;
                    width: 50px;
                }

            }
        }

        .cart-btn {
            right: 15px;
            top: 52px;

            .price_wrapper {
                display: inline-block;
                font-size: 14px;
                color: #555555;
                font-weight: bold;

                span {
                    text-align: right;

                    &.price {
                        font-weight: 700;
                    }
                }
            }

            .amount {
                display: inline-block;
                margin-left: 5px;

                i {
                    color: #555;
                    font-size: 24px;
                    vertical-align: -2px;
                }
            }
        }

        .user-btn {
            font-size: 14px;
            right: 15px;
            top: 146px;
            z-index: 2;

            i {
                font-size: 18px;
                color: $white;
            }

            span {
                margin-left: 5px;
                vertical-align: 2px;
                color: $white;
            }

            &.active {
                span {
                    color: $white;
                }
            }
        }

        .fav-btn {
            top: 54px;

            span {
                color: #555;
                font-size: 14px;
            }

            i {
                margin-left: 3px;
                color: $color1;
                vertical-align: -2px;
            }

            &:hover {
                span {
                    color: $color2;
                }
            }
        }

        .user {
            position: absolute;
            right: 0px;
            top: 180px;
            width: 300px;
            margin: 0;
            z-index: 2;
            background: #FFF;
            border: 1px solid #e5e5e5;
            border-top: none;
            border-right: none;

            .form-group:last-of-type {
                float: none;
            }

            ul {
                float: none;
            }
        }

        nav {
            display: block !important;
            margin: 0;
            overflow: visible;

            .container > ul {
                > li {
                    position: relative;
                    display: block;
                    float: left;
                    height: auto;

                    > a {
                        position: relative;
                        border-bottom: none;
                        padding: 10px 25px;
                        height: auto;
                        font-weight: 700;
                        font-size: 16px;

                        &:before {
                            content: none;
                        }

                        &:after {
                            content: ' ';
                            position: absolute;
                            right: 0;
                            top: 10px;
                            width: 1px;
                            height: 25px;
                            background: #EEE;
                        }
                    }

                    &:last-child {
                        a:after {
                            content: none;
                        }
                    }

                    ul {
                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 10;
                        opacity: 0;
                        visibility: hidden;
                        background: #FFF;
                        min-width: 250px;
                        border: 1px solid #e5e5e5;
                        transition: all 0.3s;

                        li {
                            position: relative;
                            white-space: nowrap;
                            display: block;
                            transition: none;
                            overflow: visible;
                            height: auto;

                            &:after {
                                display: none;
                            }

                            a {
                                padding: 15px 15px;
                                border-top: none;
                                color: $color1;
                                font-weight: 700;
                                height: auto;

                                &:before {
                                    content: none;
                                }
                            }

                            ul {
                                opacity: 0;
                                visibility: hidden;
                                top: -1px;
                                left: auto;
                                right: 0;
                                transform: translate(100%, 0);
                            }

                            &:hover {
                                > a {
                                    color: #000;
                                }

                                > ul {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    &:hover {
                        overflow: visible;

                        > a {
                            color: #fff;
                        }

                        > ul {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    header {
        .top {
            text-align: center;

            span:first-of-type {
                margin-left: 90px;
            }
        }

        .logo {
            height: 70px;
            position: relative;
            z-index: 2;
            margin-top: 15px;
            margin-left: 0px;
            margin-bottom: 25px;
        }

        .search {
            top: 34px;
            max-width: 600px;
        }

        .user {
            top: 160px;
        }

        .user-btn {
            top: 127px;
        }

        .fav-btn {
            top: 44px;
        }

        .cart-btn {
            top: 42px;
        }
    }
}

@media (min-width: 1440px) {
    header {
        .search {
            max-width: 700px;
        }

        .user {
            border-right: 1px solid #e5e5e5;;
        }
    }
}