@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7b0uka');
  src:  url('fonts/icomoon.eot?7b0uka#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?7b0uka') format('truetype'),
  url('fonts/icomoon.woff?7b0uka') format('woff'),
  url('fonts/icomoon.svg?7b0uka#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-basket:before {
  content: "\e91a";
}
.icon-user:before {
  content: "\e909";
}
.icon-cell_phone:before {
  content: "\e914";
}
.icon-minus:before {
  content: "\e916";
}
.icon-gratis-verzenden:before {
  content: "\e920";
}
.icon-klacht:before {
  content: "\e91b";
}
.icon-merken:before {
  content: "\e91c";
}
.icon-privacy:before {
  content: "\e91d";
}
.icon-ruilen:before {
  content: "\e91e";
}
.icon-winkel:before {
  content: "\e91f";
}
.icon-plus:before {
  content: "\e917";
}
.icon-vergroot:before {
  content: "\e918";
}
.icon-video:before {
  content: "\e919";
}
.icon-arrow-left:before {
  content: "\e90d";
}
.icon-arrow-right:before {
  content: "\e90e";
}
.icon-payment:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-youtube:before {
  content: "\e911";
}
.icon-like:before {
  content: "\e912";
}
.icon-response:before {
  content: "\e913";
}
.icon-blog:before {
  content: "\e915";
}
.icon-menu:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-submenu:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-google-plus:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e906";
}
.icon-pinterest:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e90a";
}
.icon-star-lefthalf:before {
  content: "\e90b";
}
.icon-twitter:before {
  content: "\e90c";
}
.icon-timer:before {
  content: "\e61d";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-bin:before {
  content: "\e9ac";
}
