.btn {
    display: inline-block;
    text-transform: uppercase;
    background: $color1;
    color: $white;
    border: none;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 15px;
    white-space: nowrap;
    transition: all 300ms ease;

    &.no-height {
        height: auto;
    }

    &:hover {
        background: shade($color1, 15);
        color: $white;
    }

    &.btn-white {
        background: $white;
        color: $color1;

        &:hover {
            background: #DDD;
            color: $color1;
        }
    }

    &.btn-100 {
        width: 100%;
    }

    i {
        font-size: 18px;
    }

    &:not(.btn-icon-only) {
        i {
            float: left;
            line-height: 40px;
            width: 40px;
            text-align: center;
            margin-left: -15px;
        }
    }
}
