#carousel {
    margin-top: 30px;
    
    .slide {
        position: relative;

        img {
            width: 100%;
        }
        .overlay {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            // background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));

            .text {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);

                h2,
                p {
                    margin: 0;
                    color: $white;
                }
                .btn {
                    margin-top: 15px;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #carousel {
        .slide {
            .overlay {
                .text {
                    left: 10%;

                    h2 {
                        font-size: 50px;
                    }
                    p {
                        font-size: 24px;
                    }
                    .btn {
                        text-transform: none;
                        font-size: 20px;
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #carousel {
        margin-top: 50px;
        
        .slide {
            .overlay {
                .text {
                    h2 {
                        font-size: 60px;
                    }
                    p {
                        font-size: 28px;
                    }
                    .btn {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}