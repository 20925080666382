#breadcrumbs {
    margin: 30px 0 30px 0;
    padding: 0;
    
    li {
        display: none;
        position: relative;
        color: #A4B3BB;
        font-size: 14px;
        padding: 0 5px;
        font-weight: lighter;

        &:after {
            content: '/';
            position: absolute;
            left: -5px;
            top: 0;
        }
        &:first-child {
            padding-left: 0;

            &:after {
                display: none;
            }
        }

        a {
            color: #A4B3BB;
        }
    }
}

@media (min-width: $screen-md-min) {
    #breadcrumbs {
        li {
            display: inline-block;
        }
    }
}