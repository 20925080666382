#contact {
    strong.title {
        text-transform: uppercase;
    }
    .address {
        .phone {
            display: block;
            margin: 15px 0;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .times {
        .day {
            font-weight: bold;
        }
    }

    .tour {
        position: relative;
        line-height: 20px;
        padding-left: 40px;

        i {
            position: absolute;
            font-size: 20px;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    #map {
        width: 100%;
        height: 225px;
        margin: 15px 0;
        border: none;
    }

    h2 {
        margin: 0;
        margin-bottom: 15px;
        border-bottom: 1px solid $grey;
        padding-bottom: 15px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
    }
}